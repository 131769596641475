import React, { Component } from 'react';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'react-lottie-player';

import rocketLanding from '../../animations/rocketLanding.json';

import styles from './ThumbNailViewer.module.css';

class ThumbNailViewer extends Component {

    state = {
        showRocketLanding: false
    }

    imageCarrousel = (thumbnail) => {
        let imgSet = [];
        let count = 0;
        for (let image of thumbnail.images) {
            imgSet.push(
                <div className={`${styles.ThumbHomeImgCarrousel} ImageShadow`} style={{zIndex: count + 10, backgroundImage: "url(" + image.image[0] + ")"}} key={count++}></div>
            )
        }
        return imgSet;
    }

    setShowRocketLanding = (bool) => {
        this.setState({
            showRocketLanding: bool
        })
    }

    generateThumbNails = () => {
        let result = [];

        // ########### HOME ###########    

        if (this.props.mode === 'home') {
            if (this.props.content !== null) {
                result = this.props.content.map((thumbnail, index) => {
                    if (thumbnail.caseType === 'pro') {
                        let refValue = null;
                        if (index === 0) {refValue = this.props.innerRef;}
                        return (
                            <NavLink to={{
                                    pathname: '/' + thumbnail.path + this.props.urlParams
                                }}
                                ref={refValue}
                                key={index}>
                                <div className={`${styles.ThumbNail} ThumbBackground`}>
                                    <div className={styles.ThumbHomeImg}>
                                        {/* <div className={`${styles.ThumbHomeImgCarrousel} ImageShadow`} style={{backgroundImage: "url(" + thumbnail.pageThumbnail[0] + ")", width: "100%", height: "225px"}}></div> */}

                                        <img src={thumbnail.pageThumbnail[0]} alt="" className="ImageShadow"/>
                                        {this.imageCarrousel(thumbnail)}
                                    </div>
                                    <div className={styles.ThumbHomeTxt}>
                                        <div style={{textDecoration: "none"}}>{parse(thumbnail.pageTexts[this.props.lang].description)}</div>
                                    </div>
                                    <div className={`${styles.ThumbHomeRightCol}`} >{(this.props.org === 'default') ? 
                                    <svg 
                                        viewBox={'0 0 100 50'} 
                                        >
                                            <FontAwesomeIcon 
                                                icon={['fas', 'drafting-compass']} 
                                                x="40%" 
                                            />
                                    </svg>
                                    : null}</div>
                                </div>
                            </NavLink>
                        );
                    } else if (thumbnail.caseType === 'private') {
                        return (
                            <NavLink to={{
                                    pathname: '/' + thumbnail.path + this.props.urlParams
                                }}
                                key={index}
                                onFocus ={() => this.setShowRocketLanding(true)}
                                onBlur ={() => this.setShowRocketLanding(false)}
                                onMouseEnter={() => this.setShowRocketLanding(true)}
                                onMouseLeave={() => this.setShowRocketLanding(false)}                                >
                                <div 
                                    className={`${styles.ThumbNail} ThumbBackground Private ${styles.Private}`} 
                                    >
                                    <div className={styles.ThumbHomeRightCol} id="lottie">
                                        {(this.props.org === 'default' && this.state.showRocketLanding) && (
                                            <Lottie 
                                                animationData={rocketLanding} 
                                                play
                                                loop={false}
                                                rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} 
                                                />)}
                                    </div>
                                    <div className={styles.ThumbHomeTxt}>
                                        <div style={{textDecoration: "none"}}>{parse(thumbnail.pageTexts[this.props.lang].description)}</div>
                                    </div>
                                    <div className={`${styles.ThumbHomeImg} ${styles.ThumbHomeImgPrivate}`}>
                                        <img src={thumbnail.pageThumbnail[0]} alt="" className="ImageShadow"/>
                                        {this.imageCarrousel(thumbnail)}
                                    </div>
                                </div>
                            </NavLink>
                        );
                    } else return null;
                })
            } else result = (<div>No thumbnails found</div>);

            return (
                <div className={`${styles.ThumbNailViewerHome} FocusParent`}>
                    {result}
                </div>
            );    


        // ########### FOOTER ###########    

        } else if (this.props.mode === 'next') {
            const maxNextItems = 3;
            let nextItems = [];
            let currentItem = 0;


            // check which is the current page to exclude it
            for (let i = 0; i < this.props.content.length; i++) {
                if (this.props.match === this.props.content[i].path) {
                    // check if current page is a private page
                    if (this.props.content[i].caseType === 'private') {
                        return; // don't show thumbnails on private pages
                    } else currentItem = i;
                }
            }

            // check for items after the current one and do not exceed max num of items
            for (let i = currentItem + 1; i < this.props.content.length; i++) {
                if (nextItems.length < maxNextItems && this.props.content[i].caseType === 'pro'){ 
                    nextItems.push(this.props.content[i]);
                }
            }

            // check for items before the current one and do not exceed max num of items
            for (let i = 0; i < currentItem; i++) {
                if (nextItems.length < maxNextItems && this.props.content[i].caseType === 'pro'){ 
                    nextItems.push(this.props.content[i]);
                }
            }

            let difference = maxNextItems - nextItems.length;
            for (let i = 0; i < nextItems.length; i++) {
                result.push(
                    (
                        <div 
                            className={`${styles.ThumbNail} ${styles.ThumbNailRow}`} 
                            style={(difference === 0 && i === nextItems.length-1) ? {margin: '0'} : null} 
                            key={i} 
                            onClick={() => this.props.clicked('/' + nextItems[i].path)}>
                            <div className={styles.ThumbImg}>
                                <img src={nextItems[i].pageThumbnail[0]} alt="" className="ImageShadow"/>
                            </div>
                            <div className={styles.ThumbTxt}>
                                <div className={`${styles.ThumbTitle} OverlayText`}>{parse(nextItems[i].pageTexts[this.props.lang].pageTitle)}</div>
                            </div>
                        </div>
                    )
                )
            }
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    result.push((
                        <div
                            key={result.length + i} 
                            className={`${styles.ThumbNail} ${styles.ThumbNailRow}`} 
                            style={(i === difference) ? {margin: '0'} : null}>
                        </div>
                    ));
                }
            }

            return (
                <>
                    <div><strong style={{marginBottom: '20px', textAlign: 'center'}}>{this.props.labels.otherWork}</strong></div>
                    <div className={styles.ThumbNailViewerFooter}>
                        {result}
                    </div>
                </>
            );
        }
    }

    render () {
        return (
            <>{this.generateThumbNails()}</>
        );
    }
}

export default React.forwardRef((props, ref) => <ThumbNailViewer innerRef={ref} {...props} />);