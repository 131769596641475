import React, { Component } from 'react';
import parse from 'html-react-parser';
import ImageLibrary from '../ImageLibrary/ImageLibrary';
import ThumbNailViewer from '../ThumbNailViewer/ThumbNailViewer';
import ImageLink from '../ImageLink/ImageLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../fonts/fontawesome';

import styles from './Content.module.css';

export default class Content extends Component {
    state = {
        dataLoaded: false 
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(){
        this.loadData();
    }

    loadData = () => {
        let id = window.location.pathname;
        for (let item of this.props.content) {
            if (this.state.path !== item.path && id === '/' + item.path) {
                this.setState({
                    ...item,
                    dataLoaded: true
                }, () => {
                    // console.log(this.state);
                });
            }
        }
    }

    formatHeader = (title) => {
        if (this.props.org === 'default') {
            let bgTitle = '';
            if (this.state.caseType === 'pro') {
                bgTitle = this.props.labels.navCases;
            } else bgTitle = title;
            let textWidth = bgTitle.length * 9.5;
            return(
                <svg 
                    viewBox={'0 0 ' + textWidth + ' 15'} 
                    height="100%" 
                    width="100%">
                    <text className="SVGFont1"
                        x="50%" 
                        y="76%" 
                        >
                        {bgTitle.toUpperCase()}
                    </text>
                    {(this.state.caseType === 'private') 
                        ? 
                        <>
                        <FontAwesomeIcon icon={['fas', 'rocket']} transform="shrink-12" x="41%" y="-1"/>
                        <FontAwesomeIcon icon={['fas', 'hiking']} transform="shrink-12" x="47.7%" y="-1"/>
                        </> 
                        : 
                        <FontAwesomeIcon icon={['fas', 'drafting-compass']} transform="shrink-12" x="47.7%" y="-1"/>}
                    <text className="SVGFont2" x="100%" y="76%" fontSize="0.2em">
                        {title}
                    </text>
                </svg>
            );
        } else return (<h1>{parse(title)}</h1>);
    }

    divFormatter = (object, imageArray) => {
        let objectDiv = null;
        if (object.type === 'image') {
            objectDiv = <ImageLibrary img={object.image} imageCollection={imageArray}/>
        } else if (object.type === 'img-link') {
            objectDiv = <ImageLink 
                            link={object.url} 
                            imageUrl={object.image}
                            imageDescription={object.textHover} />
        } else if (object.type === 'iframe') {
            objectDiv = (
                <div className={`${styles.iframeContainer} ImageShadow`}>
                    <iframe 
                        src={object.url} 
                        title={(Math.random() * 10000).toString()}
                        allowFullScreen="allowFullScreen"
                        frameBorder="0"   
                        />
                </div>
            )
        }
        return (
            (object.id % 2 === 0) ?
            <>
            <div className={`${styles.positionLeft} ImageHolder`}>
                {objectDiv}
                <div className={styles.Description}><p>{object.desc}</p></div>
            </div>
            <div className={styles.TextColumnRight}>
                {parse(object.text)}
            </div>
            </>
            :
            <>
            <div className={styles.TextColumnLeft}>
                {parse(object.text)}
            </div>
            <div className={`${styles.positionRight} ImageHolder`}>
                {objectDiv}
                <div className={styles.Description}><p>{object.desc}</p></div>
            </div>
            </>
        ) 
    }

    contentEvaluator = () => {
        if (!this.state.dataLoaded) {
            return (<div>404 - Page not found</div>);
        }
        let index = 0;
        let contentArray = [];
        let langTexts = this.state.pageTexts[this.props.lang];

        // handling header text and description
        contentArray.push(
            <div key={index++} order={-1}>
                <div>
                    {this.formatHeader(langTexts.pageTitle)}
                    {/* add absolute position company and icon(s) */}
                </div>
            </div>
        );
        contentArray.push(
            <div key={index++} order={-1} className="ContentUnderline">
                <div>
                    {parse(langTexts.description)}
                </div>
            </div>
        );

        // stacking image text combos
        let objectTextComboArray = [];
        let imageArray = [];
        for (let object of this.state.images) {
            imageArray.push(object.image[0]);
            let desc = '';
            if (langTexts.textBlocks[object.objId].description) {
                desc = langTexts.textBlocks[object.objId].description;
            }
            objectTextComboArray.push({
                id: object.objId,
                image: object.image[0], 
                type: 'image', 
                text: langTexts.textBlocks[object.objId].textBlock,
                desc: desc
            });
        }
        // stacking external links and iframes
        if (this.state.externals !== undefined) {
            for (let object of this.state.externals) {
                let image = '';
                if (object.image) {
                    image = object.image[0];
                }
                let desc = '';
                if (langTexts.textBlocks[object.objId].description) {
                    desc = langTexts.textBlocks[object.objId].description;
                }
                objectTextComboArray.push({
                    id: object.objId,
                    image: image, 
                    type: object.type, 
                    text: langTexts.textBlocks[object.objId].textBlock,
                    textHover: object.textHover,
                    url: object.url,
                    desc: desc
                });
            }
        }
        // sorting content based on object id set in CMS
        objectTextComboArray.sort((a, b) => (a.id > b.id) ? 1 : -1);
        // console.log(objectTextComboArray);

        for (let object of objectTextComboArray) {
            contentArray.push(
                <div key={index++} order={object.id} className={(object.id % 2 !== 0) ? `${styles.ColumnReverse} ContentUnderline` : "ContentUnderline"}>
                    {this.divFormatter(object, imageArray)}
                </div>
            )
        }

        return contentArray;
    }

    thumbNailClickHandler = (url) => {
        this.props.history.push({pathname: url + this.props.urlParams});
        // window.scrollTo(0, 0);
    }

    render () {
        return (
            <div className={styles.Content} id="content">
                <ScrollToTopOnMount />
                {this.contentEvaluator()}
                <ThumbNailViewer 
                    match={this.state.path} 
                    mode={'next'} 
                    content={this.props.content}
                    lang={this.props.lang}
                    clicked={this.thumbNailClickHandler}
                    labels={this.props.labels}
                    />
            </div>
        );
    }
}
