export const convertIPtoCountry = (lang) => {
    return new Promise ((resolve, reject) => {
        if (lang === undefined) {
            fetch('https://ipapi.co/json/')
            .then(response => {
                return response.json();
            })
            .then(ipApiData => {
                resolve(ipApiData.country_code);
            })
            .catch(error => {
                console.log('ip could not be detected:', error);
                resolve(undefined);
            });
        } else resolve(lang);                  
    })
}
