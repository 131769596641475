import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons';

import styles from './LanguageList.module.css';

class LanguageList extends Component {
    constructor() {
        super();
        this.state = {
            language: null,
            label: null
        }
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            language: this.props.lang
        }, () => {
            for (let item of this.props.list) {
                if (item.code === this.props.lang) {
                    this.selectItem(item);
                }
            }
        });
    }

    selectItem = (item) => {
        const { resetThenSet } = this.props;
        const { id, key, code, label } = item;
        
        this.setState({
            headerTitle: label + code + ' ',
            label: label
        }, () => resetThenSet(id, key));
    }

    render () {
        const { list } = this.props;
        return (
            <>
                {this.state.label}
                {list.map((item) => (
                    <div
                        className={styles.Row} 
                        key={item.id}
                        onClick={() => this.selectItem(item)}
                    >
                        {item.selected ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCheck} style={{visibility: "hidden"}}/>}
                        <p>{' ' + item.code + ' - ' + item.title}</p>
                    </div>
                ))}
            </>
        );
    }
}

export default LanguageList;