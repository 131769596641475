import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare} from '@fortawesome/free-solid-svg-icons';

import styles from './Footer.module.css';

const footer = (props) => {
    let active = 0;
    if (props.org === 'default') {
        active = 0;
    } else if (props.org === 'Ordina') {
        active = 1;
    } else if (props.org === 'Clockwork') {
        active = 2;
    }

    return (
        <div className="Footer">
            <div className={`${styles.FooterLinks} FooterLinks`}>
                <a href="https://www.linkedin.com/in/antonolivier1/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /> [ LinkedIn ]</a>
                <a href="mailto:anton.olivier.ux@gmail.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faEnvelopeSquare} /> [ Mail ]</a>
            </div>
            <div className={styles.FooterIcons}>
                <div 
                    className={`${styles.default} ${active === 0 ? styles.Active : null}`} 
                    onClick={active !== 0 ? () => props.clicked(window.location.pathname, 'default') : null}></div>
                <div 
                    className={`${styles.Ordina} ${active === 1 ? styles.Active : null}`} 
                    onClick={active !== 1 ? () => props.clicked(window.location.pathname, 'Ordina') : null}></div>
                <div 
                    className={`${styles.CW} ${active === 2 ? styles.Active : null}`} 
                    onClick={active !== 2 ? () => props.clicked(window.location.pathname, 'Clockwork') : null}></div>
            </div>
        </div>
    );
}

export default footer;