import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Selector from './DropDownSelector/Selector';

import styles from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faTimes} from '@fortawesome/free-solid-svg-icons';


class Header extends Component {
    constructor () {
        super();
        this.state = {
            menuOpen: false
        }
    }

    createLangButtons = (shape) => {
        return(
            <Selector 
                lang={this.props.lang}
                shape={shape}
                clicked={(path, lang) => {
                        this.props.clicked(path, lang);
                    }}
                />
        )
    }

    checkIfHomePage () {
        if (window.location.pathname === '/') {
            return null;
        } else {
            return (
                <NavLink to={{
                    pathname: '/',
                    hash: '',
                    search: this.props.urlParams
                }}>[ Home ]</NavLink>
            );
        }
    }

    createExperience () {
        return (
            <NavLink to={{
                pathname: '/experience',
                hash: '',
                search: this.props.urlParams
            }}>[ {this.props.labels.navExperience} ]</NavLink>
        );
    }

    createAboutMe () {
        return (
            <NavLink to={{
                pathname: '/aboutme',
                hash: '',
                search: this.props.urlParams
            }}>[ {this.props.labels.navAbout} ]</NavLink>
        );
    }

    createCases () {
        return (
            <Link to={{
                pathname: '/',
                hash: '',
                search: this.props.urlParams,
                state: {
                    fromCases: true
                }
            }}>[ {this.props.labels.navCases} ]</Link>
        );
    }

    openMenu = () => {
        document.body.style.overflowY = 'hidden';
        this.setState({
            menuOpen: true
        })
    }

    closeMenu = () => {
        document.body.style.overflowY = 'overlay';
        this.setState({
            menuOpen: false
        })
    }

    render () {
        return (
            <>
            <div className={`Header`}>
                <div id="logo"></div>
                <div id="companyLogo"></div>
                <nav id={styles.WideNav}>
                    {this.checkIfHomePage()}
                    {this.createCases()}
                    {this.createExperience()}
                    {this.createAboutMe()}
                    {this.createLangButtons('wide')}
                </nav>
                <nav id={styles.NarrowNav}>
                    <div onClick={() => this.openMenu()} className={styles.ButtonLink}>[ Menu ]</div>
                    <div 
                        id={styles.Menu} 
                        style={(this.state.menuOpen) ? {display: 'flex', opacity: 1}: {display: 'none', opacity: 0}}>
                        <div className={`${styles.Close} ${styles.ButtonLink}`} onClick={() => this.closeMenu()}><FontAwesomeIcon icon={faTimes}/></div>
                        {(window.location.pathname === '/') ?
                            null
                            :
                            <NavLink 
                                to={{
                                    pathname: '/',
                                    hash: '',
                                    search: this.props.urlParams
                                }} 
                                onClick={this.closeMenu}>
                                <FontAwesomeIcon icon={faAngleRight}/> Home
                            </NavLink>
                        }

                        <Link 
                            to={{
                                pathname: '/',
                                hash: '',
                                search: this.props.urlParams,
                                state: {
                                    fromCases: true
                                }}}
                            onClick={this.closeMenu}>
                            <FontAwesomeIcon icon={faAngleRight} /> {this.props.labels.navCases}
                        </Link>
                        <NavLink 
                            to={{
                                pathname: '/experience',
                                hash: '',
                                search: this.props.urlParams
                            }}
                            onClick={this.closeMenu}>
                            <FontAwesomeIcon icon={faAngleRight} /> {this.props.labels.navExperience}
                        </NavLink>
                        <NavLink 
                            to={{
                                pathname: '/aboutme',
                                hash: '',
                                search: this.props.urlParams
                            }}
                            onClick={this.closeMenu}>
                            <FontAwesomeIcon icon={faAngleRight} /> {this.props.labels.navAbout}
                        </NavLink>
                        <div className="ContentUnderline" />
                        {this.createLangButtons('narrow')}
                    </div>
                </nav>
            </div>
            </>
        );
    }
}

export default Header;