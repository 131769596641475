import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Header from '../components/Header/Header';
import Content from '../components/Content/Content';
import Experience from '../components/Experience/Experience';
import {dataConverter} from './DataConverter';
import {evaluateURLSearchParams} from './UrlParamEvaluator';
import {convertIPtoCountry} from './IPtoCountryConverter';
import Footer from '../components/Footer/Footer';
import ThumbNailViewer from '../components/ThumbNailViewer/ThumbNailViewer';
import MetaHandler from '../components/MetaHandler/MetaHandler';
import Intro from '../components/Intro/Intro';

class Portfolio extends Component {
    state = {
        availableLanguages: ['NL','EN', 'FY'],
        availableOrganizations: ['default','Ordina', 'Clockwork'],
        dataLoaded: false
    }

    componentDidMount () {
        if (!this.state.dataLoaded) {
            this.getData();
        }
    }

    componentDidUpdate = () => {
        if (this.props.location.state){
            if(this.props.location.state.fromCases) {
                this.scrollToTarget();
            }        
        }
    }

    targetRef = React.createRef();



    scrollToTarget = () => {
        setTimeout(() => {
            this.targetRef.focus();
            this.targetRef.scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }
    
    getData = () => {
        /* 
            1. if country is defined in url params, evaluate it. 
            2. if no country in url, check country through service
            3. if not default country, convert to extra language
            4. update state 
        */

        // 1. if country is defined in url params, evaluate it. 
        evaluateURLSearchParams(window.location.search, this.state.availableLanguages, this.state.availableOrganizations)
            .then(params => {
                // 2. if no country in url, check country through service
                convertIPtoCountry(params[0])
                    .then(ipCountryCode => {
                        // check if language is part of recognized list
                        let languageVerified = false;
                        for (let langCode in this.state.availableLanguages) {
                            if (ipCountryCode === this.state.availableLanguages[langCode]) {
                                languageVerified = true;
                            }
                        }
                        // unknown languages are defaulted to English
                        if (!languageVerified) {
                            ipCountryCode = 'EN';
                        }
                        // 3. if not default country, convert to extra language
                        dataConverter()
                            .then(data => {
                                if (data !== null) {
                                    // 4. update state
                                    this.setState({
                                        ...this.state,
                                        ...data,
                                        language: ipCountryCode,
                                        organization: params[1],
                                        dataLoaded: true
                                    }, () => {
                                        // console.log('Portfolio state:', this.state);
                                    })
                                } else console.log('No data available');
                            });
                    });
            })
    }

    getURLParams = () => {
        let params = '?language=' + this.state.language;
        if (this.state.organization) {
            params += '&organization=' + this.state.organization
        }
        return params;
    }

    getContentData = () => {
        let data = {};
        let id = window.location.pathname.substring(1);
        for (let item of this.state.content) {
            if (id === item.path) {
                data = {
                    title: item.pageTitle,
                    desc: item.pageTexts[this.state.language].description,
                    img: item.pageThumbnail[0]
                }
                return data;
            }
        }
        data = {
            title: this.state.name,
            desc: this.state.siteType,
            img: 'https://' + window.location.hostname + '/img/ao_logo_og.png'
        }
        return data;
    }

    setParameters = (org, lang) => {
        if (this.state.dataLoaded) {
            function update (param, state) {
                console.log('Parameters updated:', param, state);
            };
    
            if (org === this.state.organization || org === undefined) {
                org = null;
            } 
            if (lang === this.state.language || lang === undefined) {
                lang = null;
            }

            if (!org && lang) {
                this.setState({...this.state, language: lang},  () => update(lang, this.state));
            } else if (org && !lang) {
                this.setState({...this.state, organization: org}, () => update(org, this.state));
            } else if (org && lang) {
                this.setState({...this.state, organization: org, language: lang},  () => update(org + ' ' + lang, this.state));
            }
        }
    }

    languageClickHandler = (url, lang) => {
        if (url !== '/') {
            url = url + '?language=' + lang + (this.state.organization !== undefined ? '&organization=' + this.state.organization : '')
        }
        this.props.history.push({pathname: url});

        this.setState({
            ...this.state,
            language: lang
        }, () => {
            // console.log(this.state)
        })
    }

    organizationClickHandler = (url, org) => {
        if (url !== '/') {
            url = url + '?language=' + this.state.language + '&organization=' + org;
        }
        this.props.history.push({pathname: url});
        this.setState({
            ...this.state,
            organization: org,
            metaDataSet: false
        })
    }

    buildPage = () => {
        if (this.state.dataLoaded) {
            return (
                <>
                <MetaHandler 
                    langList={this.state.availableLanguages}
                    lang={this.state.language}
                    orgList={this.state.availableOrganizations}
                    org={this.state.organization}
                    setOrg={this.setParameters}
                    metaData={this.getContentData()}
                    metaBool={this.state.metaDataSet}
                    /> 
                <Header 
                    urlParams={this.getURLParams()}
                    labels={this.state.siteLabels[this.state.language]}
                    langList={this.state.availableLanguages}
                    lang={this.state.language}
                    clicked={this.languageClickHandler}
                    />
                <Switch>
                    <Route 
                        path="/" 
                        exact
                        render={() =>
                            <>
                            <Intro
                                text={this.state.siteLabels[this.state.language]}
                                img={this.state.homePhoto[0]}
                                urlParams={this.getURLParams()}
                                org={this.state.organization}
                                lang={this.state.language}
                                />
                            <ThumbNailViewer 
                                ref={ref => {this.targetRef = ref}}
                                mode={'home'} 
                                content={this.state.content}
                                lang={this.state.language}
                                urlParams={this.getURLParams()}
                                org={this.state.organization}
                                />
                            </>
                        }
                    /> 
                    <Route
                        path="/experience" 
                        render={(props) => (
                            <Experience
                                {...props} 
                                content={this.state.experience}
                                lang={this.state.language}
                                org={this.state.organization}
                                labels={this.state.siteLabels[this.state.language]}
                                urlParams={this.getURLParams()}
                                />
                        )} 
                    />
                    <Redirect from="/experience:id" to="/experience" />
                    {/* <Route
                        path="/experience:id" 
                        render={(props) => (
                            <Experience
                                {...props} 
                                content={this.state.experience}
                                lang={this.state.language}
                                org={this.state.organization}
                                labels={this.state.siteLabels[this.state.language]}
                                urlParams={this.getURLParams()}
                                />
                        )} 
                    /> */}
                    <Route 
                        path="/#:id" 
                        render={() =>
                            <>
                            <Intro
                                desc={this.state.siteLabels[this.state.language].homeDescription}
                                lang={this.state.language}
                                img={this.state.homePhoto[0]}
                                />
                            <ThumbNailViewer 
                                mode={'home'} 
                                content={this.state.content}
                                lang={this.state.language}
                                urlParams={this.getURLParams()}
                                />
                            </>
                        }
                    /> 
                    <Route 
                        path="/:id" 
                        render={(props) => (
                            <Content 
                                {...props} 
                                content={this.state.content}
                                lang={this.state.language}
                                org={this.state.organization}
                                labels={this.state.siteLabels[this.state.language]}
                                key={Date.now()}
                                urlParams={this.getURLParams()}
                                />
                        )} 
                    />
                </Switch>
                <Footer 
                    clicked={this.organizationClickHandler}
                    org={this.state.organization}
                    />
                </>
            );
        } else return (
            <>
            <div>Loading...</div>
            </>
        );
    }

    render () {
        return (
            <>
            {this.buildPage()}
            </>
        );
    }
}

export default withRouter(Portfolio)