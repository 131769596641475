import React, { Component } from 'react';
import DropDown from './DropDown';
import LanguageList from '../LanguageList/LanguageList';

class Selector extends Component {
    constructor() {
        super();
        
        this.state = {
            language: [
                {
                    id: 0,
                    title: 'Nederlands',
                    code: 'NL',
                    label: 'Taal: ',
                    selected: false,
                    key: 'language'
                },{
                    id: 1,
                    title: 'English',
                    code: 'EN',
                    label: 'Language: ',
                    selected: false,
                    key: 'language'
                },{
                    id: 2,
                    title: 'Frysk',
                    code: 'FY',
                    label: 'Taal: ',
                    selected: false,
                    key: 'language'
                }, 
            ]            
        }
    }

    resetThenSet = (id, key) => {
        const temp = [...this.state[key]];
      
        temp.forEach((item) => item.selected = false);
        temp[id].selected = true;
      
        this.setState({
            [key]: temp,
        }, () => {
            this.props.clicked(window.location.pathname, temp[id].code);
        });
    }

    preRender = () =>{
        if (this.props.shape === 'narrow') {
            return (
                <LanguageList
                    title={'Language: '} 
                    lang={this.props.lang}
                    list={this.state.language}
                    resetThenSet={this.resetThenSet}
                    />
            );
        } else {
            return (
                <DropDown 
                    title={'Language: '} 
                    lang={this.props.lang}
                    list={this.state.language}
                    resetThenSet={this.resetThenSet}
                />
            );
        }
    }
    
    render() {
        return (
            this.preRender()
        );
    }
}

export default Selector;