import React from 'react';
import parse from 'html-react-parser';

import styles from './ImageLink.module.css';

// const requestImageFile = require.context('../../img', true, /.png$/);

const ImageLink = (props) => {
    return (
        <>
            <div className={styles.ImageHolder}>
                <img src={props.imageUrl} alt="" className="ImageShadow"/>
                <a href={props.link} rel="noreferrer" target="_blank">
                    <div className={`${styles.TextOverlay} OverlayText`}>{parse(props.imageDescription)}</div>
                </a>
            </div>
        </>
    );
}
export default ImageLink;