import React, { Component } from 'react';
import parse from 'html-react-parser';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

import styles from './Experience.module.css';

export default class Content extends Component {
    state = {
        dataLoaded: false 
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(){
        this.loadData();
    }

    loadData = () => {
        let id = window.location.pathname;
        if (this.state.path !== this.props.content.path && id === '/' + this.props.content.path) {
            this.setState({
                ...this.props.content,
                dataLoaded: true
            }, () => {
                // console.log(this.state);
            });
        }
    }

    formatHeader = (title) => {
        if (this.props.org === 'default') {
            const bgTitle = this.props.labels.navExperience;
            let textWidth = bgTitle.length * 9.5;
            return(
                <svg 
                    viewBox={'0 0 ' + textWidth + ' 15'} 
                    height="100%" 
                    width="100%">
                    <text className="SVGFont1"
                        x="50%" 
                        y="76%" 
                        >
                        {bgTitle.toUpperCase()}
                    </text>
                    <text className="SVGFont2" x="100%" y="76%" fontSize="0.2em">
                        {title}
                    </text>
                </svg>
            );
        } else return (<h1>{parse(title)}</h1>);
    }

    contentEvaluator = () => {
        if (!this.state.dataLoaded) {
            return (<div>404 - Page not found</div>);
        }
        let index = 0;
        let contentArray = [];
        let langTexts = this.state.pageTexts[this.props.lang];

        // header
        contentArray.push(
            <div key={index++}>
                <div>
                    {this.formatHeader(this.props.labels.navExperience)}
                </div>
            </div>
        );

        // description
        contentArray.push(
            <div key={index++}>
                <div style={{marginTop: '10px'}}>
                    {parse(langTexts.description)}
                </div>
            </div>
        );    

        // work experience header
        contentArray.push(
            <div key={index++}>
                <div className={"ContentUnderline"}>
                    <h1>{parse(langTexts.experienceTitle)}</h1>
                </div>
            </div>
        );    

        // work experience list
        for (let i = 0; i < langTexts.workBlocks.length; i++) {
            const maskImageUrl = 'url(' + this.state.logos[i]+ ')';
            contentArray.push(
                <div key={index++} className={styles.WorkExperience}>
                    <div className={`${styles.WorkYears} GlobalExpColor`}><h2>{this.state.periods[i].period}</h2></div>
                    <div className={`${styles.WorkText} ContentUnderline`}>
                        <h2>{langTexts.workBlocks[i].company}</h2>
                        {parse(langTexts.workBlocks[i].description)}
                    </div>
                    <div className={`${styles.WorkLogos} GlobalExpBgColor`} style={{WebkitMaskImage: maskImageUrl, maskImage: maskImageUrl}}></div> 
                </div>
            )
        }

        // skills header
        contentArray.push(
            <div key={index++}>
                <div className={"ContentUnderline"}>
                    <h1>{parse(langTexts.skillsTitle)}</h1>
                </div>
            </div>
        );    

        // skill lists
        contentArray.push(
            <div key={index++} className={styles.SkillColumns}>
                <div>{parse(langTexts.rolesList)}</div>
                <div>{parse(langTexts.toolsList)}</div>
                <div>{parse(langTexts.otherList)}</div>
            </div>
        )

        // education header
        contentArray.push(
            <div key={index++}>
                <div className={`${styles.EducationTitle} ContentUnderline`}>
                    <h1>{parse(langTexts.educationTitle)}</h1>
                </div>
            </div>
        );    

        // education list
        for (let i = 0; i < langTexts.educationBlocks.length; i++) {
            contentArray.push(
                <div key={index++} className={`${styles.EducationBlocks} ContentUnderline`}>
                    <div>
                        <h2>{langTexts.educationBlocks[i].educationName}</h2>
                        {parse(langTexts.educationBlocks[i].institute)}
                    </div>
                </div>
            )
        }


        return contentArray;
    }

    render () {
        return (
            <div className={styles.Content} id="content">
                <ScrollToTopOnMount />
                {this.contentEvaluator()}
            </div>
        );
    }
}
