import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie-player';

import rocketInOrbit_EN from '../../animations/RocketInOrbit_EN.json';
import rocketInOrbit_NL from '../../animations/RocketInOrbit_NL.json';
import styles from './Intro.module.css';

const iconLanguageSetter = (lang) => {
    let iconSet = rocketInOrbit_EN;
    if (lang === 'NL') {
        iconSet = rocketInOrbit_NL;
    }
    const browsersWithAnimations = ['Chrome', 'Firefox'];
    for (let browser of browsersWithAnimations) {
        if (navigator.userAgent.indexOf(browser) !== -1) {
            return (
                <Lottie 
                    animationData={iconSet} 
                    play
                    speed={0.5}
                />
            )        
        }
    }
    return null;
}

const Intro = (props) => {
    return (
        <div className="Intro">
            <div className="Photo"><img src={props.img}  alt="" className={styles.Photo}/></div>
            <div className="IntroLeftCol"></div>
            <div className={`${styles.TextColumn} IntroText`}>
                <h1>{props.text.intro}</h1>
                <p>
                    <strong>
                    {props.text.me}
                    &nbsp; 
                    <Link to={{
                        pathname: '/',
                        hash: '',
                        search: props.urlParams,
                        state: {
                            fromCases: true
                        }
                    }}>
                        {props.text.design}
                    </Link>
                    &nbsp;,&nbsp; 
                    <Link to={{
                        pathname: '/aboutme',
                        hash: '',
                        search: props.urlParams
                    }}>
                        {props.text.tinker}
                    </Link>
                    &nbsp;
                    {props.text.and}
                    &nbsp; 
                    <Link to={{
                        pathname: '/aboutme',
                        hash: '',
                        search: props.urlParams
                    }}>
                        {props.text.hike}
                    </Link>
                    </strong>
                </p>
                {parse(props.text.homeDescription)}
            </div>
            <div className="IntroRightCol">
                <div className="CompanyLogo">
                    {(props.org === 'default') && (
                        iconLanguageSetter(props.lang)
                    )}
                </div>
            </div>
        </div>
    );
}

export default Intro;