import React, {Component} from 'react';

class MetaHandler extends Component {
    metaBool = false;
    organization = undefined;

    organizationStyles = {
        Default: 'default.css',
        Ordina: 'ordina.css',
        Clockwork: 'clockwork.css'
    }

    componentDidMount () {
        this.createMetaTags();
    }

    componentDidUpdate() {
        this.createMetaTags();
    }

    createMetaTags = () => {
        if (this.organization !== this.props.org) {
            if (this.props.org === undefined) {
                this.organization = 'default';
            } else this.organization = this.props.org;
            let link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = '/css/' + this.organization.toLowerCase() + '.css';
            link.setAttribute('id', 'org_style');

            if (document.getElementById('org_style') !== null) {
                let item = document.getElementById('org_style');
                item.replaceWith(link);
            }
        }

        if (true) {
            let meta_og_title = document.createElement('meta');
            meta_og_title.setAttribute('property', 'og:title');
            meta_og_title.setAttribute('id', 'og_title');
            meta_og_title.content = this.props.metaData.title;

            let ogTitle = document.getElementById('og_title');
            if (ogTitle !== null) {
                ogTitle.replaceWith(meta_og_title);
            }

            let meta_og_desc = document.createElement('meta');
            meta_og_desc.setAttribute('property', 'og:description');
            meta_og_desc.setAttribute('id', 'og_desc');
            meta_og_desc.content = this.props.metaData.desc;

            let ogDesc = document.getElementById('og_desc');
            if (ogDesc !== null) {
                ogDesc.replaceWith(meta_og_desc);
            }

            let meta_og_img = document.createElement('meta');
            meta_og_img.setAttribute('property', 'og:image');
            meta_og_img.setAttribute('id', 'og_img');
            meta_og_img.content = this.props.metaData.img;

            let ogImg = document.getElementById('og_img');
            if (ogImg !== null) {
                ogImg.replaceWith(meta_og_img);
            }

            this.metaBool = true;
        }
    }
 
    render () {
        return (
            <>
            </>
        );
    }
    
}

export default MetaHandler;