const checkOrganization = (param,organizationList) => {
    for (let org of organizationList) {
        if (param === org) {
            return param;
        }
    }
    console.log('Organization unknown. Valid organizations are: ' + organizationList);
    return undefined;     
}

const checkLanguage = (lang,languageList) => {
    for (let item of languageList) {
        if (lang === item) {
            return lang;
        }
    }
    console.log('Language unknown. Available languages are: ' + languageList);     
    return undefined;     
}

export const evaluateURLSearchParams = (location,languageList,organizationList ) => {
    return new Promise ((resolve) => {
        const query = new URLSearchParams(location);
        let org = undefined;
        let lang = undefined;
        
        for (let param of query.entries()) {
            if (organizationList && param[0] === 'organization') {
                org = checkOrganization(param[1],organizationList);
            } else if (param[0] === 'language') {
                lang = checkLanguage(param[1],languageList);
            }
        }
        if (organizationList && org === undefined) {
            org = organizationList[0];
        }
        resolve([lang, org]);
    })
}