import React, {useState} from 'react';

import styles from './ImageLibrary.module.css';

const ImageLibrary = (props) => {
    const [zoomImg, setZoomImg] = useState(false)
    const [showLightBox, setShowLightBox] = useState(false);
    const [imgToShow, setImgToShow] = useState("");
    
    const showImg = (img) => {
        setImgToShow(img);
        setShowLightBox(true);
        document.body.style.overflow='hidden';
    }

    const showPrev = (e) => {
        e.stopPropagation();
        let currentIndex = props.imageCollection.indexOf(imgToShow);
        let nextImage = '';
        if (currentIndex <= 0) {
            nextImage = props.imageCollection[props.imageCollection.length - 1];
        } else {
            nextImage = props.imageCollection[currentIndex - 1];
        }
        setImgToShow(nextImage);
    }

    const showNext = (e) => {
        e.stopPropagation();
        let currentIndex = props.imageCollection.indexOf(imgToShow);
        let nextImage = '';
        if (currentIndex >= props.imageCollection.length - 1) {
            nextImage = props.imageCollection[0];
        } else {
            nextImage = props.imageCollection[currentIndex + 1];
        }
        setImgToShow(nextImage);
    }

    const hideLightBox = () => {
        setShowLightBox(false);
        setZoomImg(false);
        document.body.style.overflow='auto';
    }

    const zoomImageLightBox = (e) => {
        e.stopPropagation();
        setZoomImg(!zoomImg);
    }

    return (
        <>
            <img className={`${styles.ImageCard} ImageShadow`} onClick={() => showImg(props.img)} src={props.img} alt=""/>
            { showLightBox ?
                <div id={styles.lightbox}>
                    <div id={styles.lightboxClose} onClick={hideLightBox}>
                        <div className={`${styles.iconClose} ${styles.icon}`}></div>
                    </div>
                    <div className={styles.prev} onClick={showPrev}>←</div>
                    <img className={zoomImg ? styles.fullsizeImageLightbox : styles.imageLightbox} onClick={zoomImageLightBox} src={imgToShow} alt=""/>
                    <div className={styles.next} onClick={showNext}>→</div>
                </div>
                : ""
            }
        </>
    );
}

export default ImageLibrary;