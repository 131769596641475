import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons';
import styles from './DropDown.module.css';

class DropDown extends Component {
    constructor() {
        super();
        this.state = {
            isListOpen: false,
            headerTitle: '',
            language: null
        }
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            headerTitle: this.props.title,
            language: this.props.lang
        }, () => {
            for (let item of this.props.list) {
                if (item.code === this.props.lang) {
                    this.selectItem(item);
                }
            }
        });
    }

    componentDidUpdate = () => {
        setTimeout(() => {
            if (this.state.isListOpen) {
                window.addEventListener('click', this.listClose);
            } else {
                window.removeEventListener('click', this.listClose);
            }
        }, 0)
    }

    listClose = () => {
        this.setState({
            ...this.state,
            isListOpen: false
        })
    }

    toggleList = () => {
        this.setState(prevState => ({
            isListOpen: !prevState.isListOpen
        }));
    }

    selectItem = (item) => {
        const { resetThenSet } = this.props;
        const { id, key, code, label } = item;
        
        this.setState({
            headerTitle: label + code + ' ',
            isListOpen: false,
        }, () => resetThenSet(id, key));
    }

    render() {
        const { isListOpen, headerTitle } = this.state;
        const { list } = this.props;
        return (
            <div className={styles.Selector}>
                <button 
                    type="button"
                    className="dd-header"
                    onClick={this.toggleList}
                    >
                    <div className={styles.Title}>[ {headerTitle} ]</div>
                </button>
                { isListOpen && (
                    <div
                        role="list" 
                        className={styles.FloatMenu}
                    >
                        {list.map((item) => (
                            <button 
                                type="button"
                                key={item.id}
                                onClick={() => this.selectItem(item)}
                                className={styles.Title}
                            >
                                {item.selected ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCheck} style={{visibility: "hidden"}}/>}
                                <p>{' ' + item.code + ' - ' + item.title}</p>
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default DropDown;