import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/performance';
import flamelink from 'flamelink/app';
import 'flamelink/content';
import 'flamelink/storage';

var firebaseConfig = {
    apiKey: "AIzaSyB_qW44a5oMt8pCqdUNy9yJ2JcdBup4S7Y",
    authDomain: "portfolio-cb2dc.firebaseapp.com",
    databaseURL: "https://portfolio-cb2dc-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "portfolio-cb2dc",
    storageBucket: "portfolio-cb2dc.appspot.com",
    messagingSenderId: "635824212509",
    appId: "1:635824212509:web:26725293739e7db537d4f4",
    measurementId: "G-8T2FHVHEZQ"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

const app = flamelink({
  firebaseApp,
  env: 'production', // optional, defaults to `production`
  locale: 'en-US', // optional, defaults to `en-US`
  dbType: 'rtdb' // optional, defaults to `rtdb` - can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
})

export const dataConverter = () => {
    return new Promise (resolve => {
        getLabels().then(labels => {
            getExperience().then(experience => {
                getContent().then(content => {
                    // replace default texts with extra language fragments
                    const sortedContent = sortLanguages(content);
                    imageReplacer(sortedContent).then(replacedContent => {
                        let convertedContent = sortNewestFirst(replacedContent);
                        convertedContent = {
                            ...convertedContent,
                            experience: experience, 
                            homePhoto: labels.homePhoto,
                            siteLabels: labels.siteLabels,
                            siteType: labels.siteType,
                            name: labels.name
                        };
                        resolve(convertedContent);
                    })
                    .catch(() => {resolve(null)});
                })
                .catch(() => {resolve(null)});
            })    
            .catch(() => {resolve(null)});
        })
        .catch(() => {resolve(null)});
    });
}

const getContent = () => {
    return new Promise ((resolve, reject) => {
        app.content.get({ schemaKey: 'portfolioCases' })
        .then(content => {
            resolve(content);
        })
        .catch(error => {
            console.log('Cannot get content: ', error);
            reject(null);
        });
    });    
}

const getExperience = () => {
    return new Promise ((resolve, reject) => {
        app.content.get({ schemaKey: 'portfolioExperience'})
        .then(experience => {
            let langSet = {};
            for (let languageSet of experience.pageTexts) {
                langSet[languageSet.language] = {...languageSet};
            }

            const prefix = 'img/';
            const logoSet = [
                prefix + 'cw-por-logo.svg',
                prefix + 'cw-volksbank-logo.svg',
                prefix + 'cw-na-logo.svg',
                prefix + 'cw-kluwer-logo.svg',
                prefix + 'cw-rabobank-logo.svg',
                prefix + 'cw-kpn-logo.svg'
            ];

            experience = {
                ...experience,
                pageTexts: langSet,
                logos: logoSet
            }
            resolve(experience);
        })
        .catch(error => {
            console.log('Cannot get experience: ', error);
            resolve(null);
        });
    })
}

const getLabels = () => {
    return new Promise ((resolve, reject) => {
        app.content.get({ schemaKey: 'portfolioLabels' })
        .then(content => {
            let langSet = {};
            for (let languageSet of content.siteLabels) {
                langSet[languageSet.language] = {...languageSet};
            }
            convertIdToURL(content.homePhoto).then(() => {
                content = {
                    ...content,
                    siteLabels: langSet
                }
                resolve(content);
            });
        })
        .catch(error => {
            console.log('Cannot get site labels: ', error);
            reject(null);
        });
    });    
}

const sortLanguages = (content) => {
    // take the data from the text language sections 
    // and name the object property by language
    for (let item in content) {
        let tempItem = {}
        for (let texts of content[item].pageTexts) {
            tempItem[texts.language] = texts;
            delete tempItem[texts.language].language;
        }
        content[item] = {
            ...content[item],
            pageTexts: tempItem
        };
    }

    return content;
}

const imageReplacer = (content) => {
    return new Promise ((resolve, reject) => {
        let imageArray = [];
        for (let item in content) {
            imageArray.push(content[item].pageThumbnail);

            if (content[item].images.length !== 0) {
                for (let img of content[item].images) {
                    imageArray.push(img.image);
                }
            }
            if (content[item].externals && content[item].externals.length !== 0) {
                for (let external of content[item].externals) {
                    if (external.type === 'img-link') {
                        imageArray.push(external.image);
                    }
                }
            }
        }
        const promises = imageArray.map((promise) => {
            return convertIdToURL(promise).then(() => {
                // force return to wait until function has completed
            });
        });
        Promise.all(promises)
            .then(() => {
                resolve(content);
            })
            .catch(error => {
                console.log('Cannot replace images: ', error)
                reject(null);
            });
    })
}

const convertIdToURL = (location) => {
    return new Promise ((resolve, reject) => {
        app.storage.getURL({fileId: location[0]}, {
            size: {
              width: 1080,
              height: 9999,
              quality: 1
            }
        })
        .then(url => {
            location[0] = url;
            resolve('success');
        }).catch(error => {
            console.log('Cannot convert id to URL:', error);
            resolve('fail');
        });
    })
}

const sortNewestFirst = (content) => {
    let sortedContentArray = [];
    for (let item in content) {
        sortedContentArray.push(content[item]);
    }

    const contentObj = sortedContentArray.sort((a,b) => {
        return Date.parse(b.periodStart) - Date.parse(a.periodStart);
    })

    return {
        content: contentObj,
    }; 
}