import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faDraftingCompass,
    faRocket,
    faHiking,
    faCircle
} from '@fortawesome/free-solid-svg-icons';
import {
    faLinkedin,
    faMedium
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faDraftingCompass,
    faRocket,
    faHiking,
    faLinkedin,
    faMedium,
    faCircle
);